<template>
  <div class="px-5 mt-8" id="page-domains">
    
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <DomainsDataTable
          :domains="domains"
          @deleteDomain="deleteDomain"
          @selectDomain="selectDomain"
          @createDomain="createDomain"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card
          v-if="creating || editing"
          title=""
          class="mb-2 mt-8 edit-domain"
          hide-footer
        >
          <div>
            <b-form class="mt-8" @submit="saveData">
              <b-form-group id="input-group-domain" label="Domän" label-for="input-domain">
                <b-form-input
                  id="input-domain"
                  v-model="domain"
                  placeholder="test.se"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary">Spara</b-button>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
  
</template>

<style lang="scss" scoped>

</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import DomainsDataTable from '@/view/pages/ml/settings/domains/DomainsDataTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'domains-settings',
  components: {
    DomainsDataTable
  },
  mixins: [ toasts ],
  props: ['company'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      securityTypes: [{ value: 'ssl', text: 'SSL' }],
      domains: [],
      domain: '',
      creating: false,
      editing: false,

    };
  },
  watch: {
    
  },
  methods: {
    createDomain() {
      this.creating = true;
      this.domain = '';
    },
    selectDomain(domain) {
      this.domain = domain;
      this.editing = true;
    },
    deleteDomain(domain) {
      this.domains = this.domains.filter(item => item !== domain);

      this.putSettings();
    },
    loadData() {
      axios 
        .get(`/company/system`)
        .then(res => {
          
          if (res.data.company_settings && res.data.company_settings.sendgrid_domains) {
            this.domains = res.data.company_settings.sendgrid_domains;
          }
          else {
            this.domains = [];
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta systemförening');
        });
    },
    saveData(e) {
      e.preventDefault();

      if (this.domains.find(item => item === this.domain)) {
        return;
      }

      this.domains.push(this.domain);

      this.putSettings();
    },

    putSettings() {
      axios
        .put(`/company/settings/system`, { sendgrid_domains: this.domains })
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
            this.loadData();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        });
    },

  }
};
</script>
