import { render, staticRenderFns } from "./TitleTable.vue?vue&type=template&id=cd20e85e&scoped=true"
import script from "./TitleTable.vue?vue&type=script&lang=js"
export * from "./TitleTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd20e85e",
  null
  
)

export default component.exports