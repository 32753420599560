<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"></span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th>Domän</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in domains">
              <APIConnectionTableRow
                v-bind:key="i"
                :domain="item"
                :currentCompanyId="currentCompanyId"
                @deleteDomainClicked="deleteDomainClicked"
                @toastr="toastr"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import APIConnectionTableRow from '@/view/pages/ml/settings/apiconnection/APIConnectionTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'api-connection-table',
  props: ['domains', 'currentCompanyId'],
  emits: ['deleteDomainClicked'],
  components: {
    APIConnectionTableRow
  },
  mixins: [ toasts ],
  methods: {
    deleteDomainClicked(id) {
      this.$emit('deleteDomainClicked', id);

    }
  },
  data() {
    return {

    };
  }
};
</script>

