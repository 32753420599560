<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Domäner</span>
      </h3>
      <div class="card-toolbar">
        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="createDomain"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Ny Domain</a
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th>Namn</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in domains">
              <DomainsDataTableRow
                v-bind:key="i"
                :domain="item"
                @deleteDomain="deleteDomain"
                @selectDomain="selectDomain"
                @toastr="toastr"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import DomainsDataTableRow from '@/view/pages/ml/settings/domains/DomainsDataTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'domains-data-table',
  props: ['domains'],
  emits: ['selectDomain', 'deleteDomain', 'createDomain'],
  mixins: [ toasts ],
  components: {
    DomainsDataTableRow
  },
  methods: {
    selectDomain(domain) {
      this.$emit('selectDomain', domain);
    },
    deleteDomain(domain) {
      this.$emit('deleteDomain', domain);
    },
    createDomain() {
      this.$emit('createDomain');
    }
  },
  data() {
    return {

    };
  }
};
</script>

