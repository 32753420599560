<template>
  <div class="px-5 mt-8" id="page-api-connection">
    <Confirm
          title="Starta om"
          message="Vill du starta om servern? Det kan dröja några minuter innan systemet är uppe igen"
          ref="rebootConfirm"
          :type="'success'"
          @confirm="onConfirmReboot"
        />

    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
          <div>
            <div class="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
              <div class="alert-icon"><i class="flaticon-warning"></i></div>
              <div class="alert-text">För att er hemsida ska kunna få tillgång till systemets API tjänster behöver ni lägga
              till era domäner som ska ha tillgång till systemet. Glöm inte att lägga till https://
              eller http://</div>
          </div>
          
          <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
              <div class="alert-icon"><i class="flaticon-warning"></i></div>
              <div class="alert-text">Domänerna kommer inte ha tillgång förrän servern startats om!</div>
          </div>

          <b-button variant="danger" v-if="!rebooting" @click="rebootClicked">
            Starta om server
          </b-button>



            <b-form class="mt-8" @submit="saveData">
              <b-input-group>
                <b-form-input v-model.trim="text" placeholder="https://exempel.se"></b-form-input>
                <b-input-group-append>
                  <b-button variant="outline-success" type="submit">{{$t('COMMON.ADD')}}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>

            <APIConnectionTable 
              class="mt-4"
              :domains="domains" 
              @deleteDomainClicked="deleteDomainClicked" />
            
          </div>
        </b-card></b-col
      >
    </b-row>
  </div>
</template>

<style lang="scss" scoped>

</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import APIConnectionTable from '@/view/pages/ml/settings/apiconnection/APIConnectionTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'api-connection-settings',
  components: {
    APIConnectionTable,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      text: '',
      form: {},
      domains: [],
      rebooting: false
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadData();
      }
    }
  },
  methods: {
    rebootClicked() {
      this.$refs['rebootConfirm'].show();
    },
    onConfirmReboot() {
      this.rebooting = true;
      axios 
        .post('/system/reboot')
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), 'Servern startas om');
        })
        .catch(err => {
          this.rebooting = false;
        });
    },
    async loadData() {
      axios 
        .get(`/system/origins`)
        .then(res => {
          this.domains = res.data;
        })
        .catch(err => {});
    },
    
    saveData(e) {
      e.preventDefault();

      axios
        .post('/company/origin', { company_id: this.currentCompanyId, origin: this.text })
        .then(res => {
          if (res.status === 201) {
            this.domains.push(res.data);
            this.toastr('success', this.$t('COMMON.OK'), 'Lade till domän');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lägga till domän');
        });
    },
    deleteDomainClicked(domain_id) {
      axios
        .delete(`/company/origin/${domain_id}`)
        .then(res => {
          this.domains = this.domains.filter(item => item.id !== domain_id);

          this.toastr('success', this.$t('COMMON.OK'), 'Domänen togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort domän');
        });
    },
    
  }
};
</script>
